import { Component, OnInit, Input, Inject, ElementRef, Renderer2 } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AlertasService } from '../services/alertas.service';
import * as configs from '../configurable.json';
import { environment } from '../../environments/environment';

declare var google;
const { Geolocation, Network } = Plugins;
@Component({
  selector: 'google-map',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit {

  public telefono: string = environment.telefono;
  mapref: any;
  markref: any;
  markref2: any;
  direccion: any;
  search: any;
  @Input('destino') destino: any;
  @Input('coord_origen') coord_origen: any;
  location: any = {
    coords: {
      lat: 0,
      lng: 0
    }
  };
  constructor(
    public alert: AlertasService,
    public modal: ModalController
  ) { }

  ngOnInit() {
    this.getLocation().then(() => {
      this.iniciarMapa();
    }).catch(() => {
      this.alert.showAlerta("No logramos obtener su posición actual.", "", "");
      this.getIpLocation().then(r => {
        this.location.lat = r.latitude;
        this.location.lng = r.longitude;
        console.log(this.location);
      }).then(() => {
        this.iniciarMapa();
      });
    });
  }

  async modalDismissClose() {
    this.modal.dismiss({
      dismissed: false
    })
      .then(() => {
        this.modal = null;
      });
  }
  async modalDismiss() {
    // console.log(this.location);
    this.modal.dismiss({
      dismissed: true,
      direccion: this.direccion,
      coordenadas: this.location,
    })
      .then(() => {
        this.modal = null;
      });
  }

  async iniciarMapa() {
    this.createMap()
      .then(() => {
        this.addMark();
      });
  }
  /** Si `s:boolean`  es `true` entonces no está recibiendo el resultado de un evento
   * en cambio recibe coordenadas duras.
   * Si es `false` recibirá el resultado de un evento
   * @param e Evento
   * @param s Flag
   * @async
   */
  async moveMark(e: any, s: boolean = false) {
    this.markref.setAnimation(google.maps.Animation.DROP);
    if (s) {
      this.markref.setPosition(e);
      this.location = e.toJSON();
    } else {
      this.markref.setPosition(e.latLng);
      this.reverseGmaps(e.latLng.toUrlValue()).then(r => {
        if (r[0]) {
          this.direccion = this.formatDireccion(r);
        } else {
          this.direccion = "Dirección no disponible, reintente.";
        }
      });
      this.location = e.latLng.toJSON();
    }
  }

  async addMark() {
    var label = "A";
    if(this.destino){
      label = "B";
    }
    if(this.destino && Object.keys(this.coord_origen).length !== 0){
      console.log(this.coord_origen);
      this.markref2 = new google.maps.Marker({
        position: {
          lat: this.coord_origen.lat,
          lng: this.coord_origen.lng
        },
        label: "A",
        map: this.mapref
      });
    }
    this.markref = new google.maps.Marker({
      position: {
        lat: this.location.lat,
        lng: this.location.lng
      },
      zoom: 12,
      label: label,
      map: this.mapref,
      draggable: true,
      animation: google.maps.Animation.DROP,
      title: "Hola mundo"
    });
    google.maps.event.addListener(this.markref, "dragend", (e) => {
      this.moveMark(e);
    });
    google.maps.event.addListener(this.markref, "tilesloaded", (e) => {
      this.moveMark(e);
    });
    google.maps.event.addListener(this.markref, "position_changed", (e) => {
      // console.log(e);
    });
    /* finally */
    this.reverseGmaps(this.markref.position.toUrlValue())
      .then(r => {
        if (r[0]) {
          this.direccion = this.formatDireccion(r);
        } else {
          this.direccion = "Dirección no disponible, reintente."
        }
      });
  }

  async createMap() {
    const mapele: HTMLElement = document.getElementById('map');
    const search: HTMLElement = document.getElementById('pac-input');
    const search2: HTMLInputElement = <HTMLInputElement>document.getElementById('pac-input');
    this.mapref = new google.maps.Map(mapele, {
      center: {
        lat: this.location.lat,
        lng: this.location.lng
      },
      zoom: 15,
      streetViewControl: false,
      clickableIcons: false,
      disableDefaultUI: true
    });
    /* search bar  */
    this.search = new google.maps.places.SearchBox(search);
    this.mapref.controls[google.maps.ControlPosition.TOP_LEFT].push(search);
    google.maps.event.addListener(this.mapref, 'bounds_changed', () => {
      this.search.setBounds(this.mapref.getBounds());
    });
    google.maps.event.addListener(this.search, 'places_changed', () => {
      let places = this.search.getPlaces();
      // console.log(places.length);
      if (places.length >= 1) {
        this.direccion = this.formatDireccion(places);
        this.moveMark(places[0].geometry.location, true);
        this.mapref.panTo(places[0].geometry.location);
      } else {
        this.alert.showToast("", "No se encontró esa dirección", 2, "TOP", "dark");
      }
      console.log(places);
    });
    /* end search */

    /* listener */
    google.maps.event.addListener(this.mapref, 'click', (e) => {
      this.moveMark(e);
    });
  }
  /** obtener gps  */
  async getLocation() {
    this.location = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true
    });
    this.location = { lat: await this.location.coords.latitude, lng: await this.location.coords.longitude };
    console.log("Posición:", await this.location);
  }

  /** Obtener IP
   * @async
   */
  async getIpLocation() {
    let res: any;
    try {
      res = await fetch('https://freegeoip.app/json/');
      res = await res.json();
    } catch (error) {
      res = { status: "fail" };
    }
    return res;
  }

  /** Geocode Reverse 
   * @param coordenadas {String} Coordenadas en formato de string.
   * @async
   */
  async reverseGmaps(coordenadas: string) {
    return new Promise((resolve, reject) => {
      let coords: any = coordenadas.split(',', 2);
      coords = { lat: parseFloat(coords[0]), lng: parseFloat(coords[1]) };
      let geocoder: any = new google.maps.Geocoder();
      geocoder.geocode({
        'location': coords
      }, (res:any, status:any) => {
        if(status == "OK"){
          console.log("Estado", status, "Resulados", res);
          return resolve(res);
        } else {
          return reject([]);
        }
      });
      // console.log(coords);
    });
  }

  formatDireccion(data_direccion: any){
    let calle = '';
    let numero = '';
    let comuna = '';
    let provincia = '';
    let partes = data_direccion[0].address_components;
    partes.forEach(parte => {
      if(parte.types.includes('street_number')){
        numero = parte.long_name;
      }
      if(parte.types.includes('route')){
        calle = parte.long_name;
      }
      if(parte.types.includes('administrative_area_level_3')){
        comuna = parte.long_name;
      }
      if(parte.types.includes('administrative_area_level_2')){
        provincia = parte.long_name;
      }
    });
    return calle + ' ' + numero + ', ' + comuna + ', ' + provincia;
  }

}
