
<ion-header>
  <ion-toolbar color="primary">
     <ion-buttons slot="start">
      <ion-back-button color="light" (click)="modalDismissClose()" text=""></ion-back-button>
    </ion-buttons>
    <ion-title>Ubicación</ion-title>
    <ion-buttons slot="end">
      <ion-button class="confirmar-mapa" (click)="modalDismiss()" color="">
        Confirmar
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
<input type="text" id="pac-input" placeholder="Escribe tu dirección">
  <div id="map"></div>
  <div class="info">
    {{direccion}}
  </div>
</ion-content>
